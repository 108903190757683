import { createGlobalStyle } from 'styled-components';

import CocogooseUltralight from '~/assets/fonts/cocogoose/CocogooseProUltralight-trial.ttf';
import 'tippy.js/dist/tippy.css';

export default createGlobalStyle`
  
  
  @import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');
  @font-face {
    font-family: 'Cocogoose Ultralight';
    src: url(${CocogooseUltralight});
  }


  * {
    margin: 0;
    padding: 0;
    outline: none;
    box-sizing: border-box;
  }

  *:focus {
    outline: none;
  }

  html {
    
  }

  body, html {
    height: 100%;
    width: 100%;
  }

  body {
    font-family: 'Cocogoose Ultralight'
  }


  a {
    cursor: pointer;
    text-decoration: none;
  }

  
  .title {
    color: #001140;
    font-size: 26px;
    font-weight: 700;
    text-align: center;
    margin: 20px 0px;

  }

 

`;
