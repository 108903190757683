import React from 'react';

import { Rombo } from '~/components';

import { Container, Content, Testimony, Text } from './styles';

function Testimonials() {
  return (
    <Container id="characteristics">
      <Content>
        <Testimony>
          <Rombo bgColor="#fff">Missão</Rombo>
          <Text>
            Criar excelentes oportunidades de negócios em imóveis, que
            proporcione grandes lucros em curto tempo para os investidores,
            excelente remuneração para os empregados, parceiros e sócios, e boas
            oportunidades para os compradores.
          </Text>
        </Testimony>

        <Testimony>
          <Rombo bgColor="#fff">Visão</Rombo>
          <Text>
            Ser a maior e melhor agenciadora de investimentos em imóveis de
            leilões no Brasil, com alta rentabilidade.
          </Text>
        </Testimony>

        <Testimony>
          <Rombo bgColor="#fff">Valores</Rombo>
          <Text>
            Honestidade, Verdade, Simplicidade, Lealdade, Eficácia, Eficiência e
            Excelência.
          </Text>
        </Testimony>
      </Content>
    </Container>
  );
}

export default Testimonials;
