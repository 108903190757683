import React from 'react';

import PropTypes from 'prop-types';

import { ReactComponent as backgroundSVG } from '~/assets/img/section-about.svg';

import { Container } from './styles';

function Section({ title, background, children }) {
  return (
    <Container background={background}>
      {title && (
        <h2 style={{ marginBottom: 40 }} className="title">
          {title}
        </h2>
      )}

      {children}
      <backgroundSVG />
    </Container>
  );
}

Section.propTypes = {
  title: PropTypes.string,
  background: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Section.defaultProps = {
  title: '',
  background: '',
};

export default Section;
