import styled from 'styled-components';

export const Container = styled.div`
  padding: 41.42px;
  float: left;

  &::before {
    content: '';
    background-color: #f37835;
    transform: rotate(42deg);
    width: 200px;
    position: absolute;
    height: 200px;
    border-radius: 2px;
    display: block;
  }
`;

export const Content = styled.div`
  border-radius: 2px;
  background-color: ${props => props.bgColor};
  padding: 0;
  width: 200px;
  height: 200px;
  margin: auto;
  text-align: center;
  display: block;
  border: 5px solid transparent;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);
  transition: transform 0.1s cubic-bezier(0, 1.8, 1, 1.8);
`;

export const Text = styled.div`
  color: white;
  position: relative;
  overflow: hidden;
  padding: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(-45deg);
  -ms-transform: translateY(-50%) rotate(-45deg);
  transform: translateY(-50%) rotate(-45deg);

  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  color: ${props => (props.bgColor === '#fff' ? '#001140' : '#fff')};

  @media (max-width: 760px) {
    font-size: 16px;
  }
`;
