import React from 'react';
import { FaPaperPlane } from 'react-icons/fa';
import { toast } from 'react-toastify';

import { Formik } from 'formik';
import * as Yup from 'yup';

import MapPNG from '~/assets/img/map.png';
import api from '~/services/api';

import {
  Container,
  Content,
  MapContent,
  MapTitle,
  Map,
  FormContent,
  FormTitle,
  Form,
  Label,
  Input,
  SubmitButton,
  Help,
} from './styles';

const schema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
  email: Yup.string()
    .email('Digite um e-mail válido')
    .required('E-mail é obrigatório'),
  subject: Yup.string().required('Assunto é obrigatório'),
  message: Yup.string().required('Mensagem é obrigatório'),
});

function Contact() {
  async function handleFormSubmit(values, { resetForm }) {
    await api.post('/contat/send', values);
    resetForm({ values: { name: '', email: '', subject: '', message: '' } });
    toast.success('Formulário enviado!');
  }

  return (
    <Container>
      <Content>
        <MapContent>
          <MapTitle>Onde Estamos</MapTitle>,
          <Map src={MapPNG} />
        </MapContent>

        <FormContent id="contact">
          <FormTitle>Contate-nos</FormTitle>,
          <Formik
            enableReinitialize
            validationSchema={schema}
            initialValues={{
              name: '',
              email: '',
              subject: '',
              message: '',
            }}
            validateOnChange={false}
            onSubmit={handleFormSubmit}
          >
            {({ values, errors, handleSubmit, handleChange }) => {
              console.log({ values });
              return (
                <Form onSubmit={handleSubmit}>
                  <Label>Seu Nome</Label>
                  <Input
                    name="name"
                    placeholder="Seu Nome"
                    value={values.name}
                    onChange={handleChange}
                  />
                  {Object.prototype.hasOwnProperty.call(errors, 'name') && (
                    <Help>{errors.name}</Help>
                  )}

                  <Label>Seu Email*</Label>
                  <Input
                    name="email"
                    placeholder="Seu Email*"
                    value={values.email}
                    onChange={handleChange}
                  />
                  {Object.prototype.hasOwnProperty.call(errors, 'email') && (
                    <Help>{errors.email}</Help>
                  )}

                  <Label>Seu Assunto</Label>
                  <Input
                    name="subject"
                    placeholder="Seu Assunto..."
                    value={values.subject}
                    onChange={handleChange}
                  />
                  {Object.prototype.hasOwnProperty.call(errors, 'subject') && (
                    <Help>{errors.subject}</Help>
                  )}

                  <Label>Sua Mensagem</Label>
                  <Input
                    name="message"
                    placeholder="Sua Mensagem..."
                    value={values.message}
                    onChange={handleChange}
                  />
                  {Object.prototype.hasOwnProperty.call(errors, 'message') && (
                    <Help>{errors.message}</Help>
                  )}

                  <SubmitButton onClick={handleSubmit}>
                    Enviar
                    <FaPaperPlane size={24} color="#fff" />
                  </SubmitButton>
                </Form>
              );
            }}
          </Formik>
        </FormContent>
      </Content>
    </Container>
  );
}

export default Contact;
