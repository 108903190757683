import styled from 'styled-components';

import { ReactComponent as CurveTopSVG } from '~/assets/img/curve-top.svg';

export const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  padding: 0px 30px;
  justify-content: center;
  margin-bottom: 40px;
  background-color: #001140;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Testimony = styled.div`
  max-width: 300px;
  text-align: center;

  @media (max-width: 768px) {
    margin: 8px 0px;
  }
`;

export const Text = styled.span`
  font-size: 14px;
  display: inline-block;
  margin-top: 20px;
  height: 137px;
  color: #fff;

  @media (max-width: 768px) {
    height: auto;
  }
`;

export const CurveTop = styled(CurveTopSVG)`
  position: absolute;
  width: 100%;
  fill: #f37220;
`;
