import styled from 'styled-components';

export const Container = styled.div`
  background-color: #001140;
  display: flex;
  border-top: 6px solid #f37220;
  justify-content: center;
  margin-top: 40px;
  padding: 0px 30px;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: row;
  padding: 55px;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Logo = styled.img``;

export const Sections = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 150px;

  @media (max-width: 768px) {
    margin-left: 0px;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  color: #fff;
`;

export const SectionTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 4px;
`;

export const SectionText = styled.div`
  font-size: 14px;
`;

export const SocialSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  svg {
    margin: 0px 15px;
  }

  @media (max-width: 768px) {
    justify-content: center;
    text-align: center;
    margin-left: 0px;
  }
`;
