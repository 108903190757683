import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0px;
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  max-width: 1380px;
  flex-direction: row;
  justify-content: space-around;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const MapContent = styled.div`
  display: flex;
  align-self: center;
  flex-direction: column;
`;

export const MapTitle = styled.h2`
  font-size: 28px;
  color: #001140;
  margin-bottom: 15px;
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
`;

export const Map = styled.img`
  @media (max-width: 1024px) {
    max-width: 400px;
  }
`;

export const FormContent = styled.div`
  display: flex;
  align-self: center;
  flex-direction: column;
  max-width: 500px;
  width: 100%;

  @media (max-width: 1024px) {
    max-width: 400px;
  }

  @media (max-width: 768px) {
    margin: 40px 0px;
    max-width: 500px;
  }

  @media (max-width: 425px) {
    max-width: 400px;
  }
`;

export const FormTitle = styled.h2`
  font-size: 20px;
  color: #001140;
  margin-bottom: 15px;
  font-weight: 600;

  @media (max-width: 768px) {
    text-align: center;
    font-size: 28px;
  }
`;

export const Form = styled.form`
  border: 2px solid #001140;
  padding: 50px;
  padding-top: 40px;
`;

export const Label = styled.label`
  display: flex;
  margin-bottom: 4px;
  margin-top: 10px;
  font-size: 14px;
`;

export const Input = styled.input`
  padding: 8px;
  width: 100%;

  &:focus {
    border-color: #f37220;
    outline: 0;
    border-style: solid;
  }
`;

export const SubmitButton = styled.div`
  width: 100%;
  height: 40px;
  background-color: #f37220;
  color: #fff;
  text-align: center;
  padding: 11px;
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #001140;
  }

  svg {
    margin-left: 8px;
  }
`;

export const Help = styled.p`
  color: #dc3545 !important;
  margin: 4px 0px;
  font-size: 12px;
`;
