import React from 'react';

import { Container, Shadow, Content, Section, Title, List } from './styles';

function About() {
  return (
    <Container id="services">
      <Shadow />

      <Content>
        <Section>
          <Title>O Que Fazemos</Title>
          <List>
            <li>Selecionamos bons imóveis em leilões;</li>
            <li>Avaliamos financeiramente e juridicamente;</li>
            <li>Indicamos a parceiros de acordo ao perfil;</li>
            <li>Arrematamos e desembaraçamos juridicamente;</li>
            <li>Desocupamos amigavelmente ou judicialmente;</li>
            <li>Indicamos terceiros para reforma;</li>
            <li>Indicamos terceiros para venda do imóvel.</li>
          </List>
        </Section>
      </Content>
    </Container>
  );
}

export default About;
