import React, { useCallback, useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';

import { useOutsideClick, useOutsideLeave } from '~/hooks';

import { Wrap, ChildrenContainer, Container } from './styles';

const Popover = ({ trigger, content, placement, children }) => {
  const [visible, setVisible] = useState(false);
  const wrapRef = useRef();
  const containerRef = useRef();
  const [arrowRight, setArrowRight] = useState(4);

  const outsideClick = useCallback(() => {
    if (visible === true) {
      setVisible(false);
    }
  }, [visible, setVisible]);

  const outsideLeve = useCallback(() => {
    setVisible(false);
    wrapRef.current.removeEventListener('mouseleave', outsideLeve);
  }, [wrapRef]);

  function handleClick() {
    if (trigger === 'click') {
      setVisible(!visible);
    }
  }

  function handleLeave() {
    if (trigger === 'hover' && visible === false) {
      setVisible(true);
      wrapRef.current.addEventListener('mouseleave', outsideLeve);
    }
  }

  useOutsideClick(wrapRef, outsideClick);

  useOutsideLeave(wrapRef, outsideLeve);

  useEffect(() => {
    const el = containerRef.current;
    if (placement === 'center' && el) {
      setArrowRight(el.offsetWidth / 2);
    }
  }, [containerRef, visible, placement]);

  return (
    <Wrap ref={wrapRef}>
      <ChildrenContainer onClick={handleClick} onMouseEnter={handleLeave}>
        {children}
      </ChildrenContainer>
      {visible && (
        <Container
          className="app-popover"
          placement={placement}
          arrowRight={arrowRight}
          trigger={trigger}
          ref={containerRef}
        >
          {content}
        </Container>
      )}
    </Wrap>
  );
};

Popover.propTypes = {
  trigger: PropTypes.string,
  content: PropTypes.node.isRequired,
  placement: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Popover.defaultProps = {
  trigger: 'hover',
  placement: 'center',
};

export default Popover;
