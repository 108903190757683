import React, { useState, useEffect } from 'react';
import { MdMenu } from 'react-icons/md';
import { useMediaQuery } from 'react-responsive';

import MiniLogoPNG from '~/assets/img/favicon.png';
import LogoPNG from '~/assets/img/logo.png';

import Popover from '../Popover';
import {
  Banner,
  Shadow,
  Wrap,
  Container,
  Content,
  Logo,
  MiniLogo,
  Right,
  Links,
  Link,
  Text,
  TextProperty,
  MiniLogoText,
  AndText,
} from './styles';

const Menu = () => {
  return (
    <Links>
      <Link href="#history">Quem Somos</Link>
      <Link href="#testimonials">Clientes</Link>
      <Link href="#services">Serviços</Link>
      <Link href="#contact">Contatos</Link>
    </Links>
  );
};

const Header = () => {
  const [bgColor, setBgColor] = useState(true);
  const isTablet = useMediaQuery({
    query: '(max-width: 768px)',
  });

  function onScroll() {
    const { pageYOffset } = window;
    setBgColor(pageYOffset < 200 ? 'transparent' : 'rgba(0, 17, 64, 0.7)');
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <Wrap>
      <Banner>
        <Shadow>
          <Container className="text-container">
            <Content className="text-content">
              <Text>INVISTA EM</Text>

              <TextProperty>
                IMÓVEI
                <MiniLogoText src={MiniLogoPNG} /> <AndText>E</AndText> <br />{' '}
                ENRIQUEÇA
              </TextProperty>
              {/* <OpportunityButton>
                OPORTUNIDADES COMPRA E VENDA
              </OpportunityButton> */}
            </Content>
          </Container>
        </Shadow>
      </Banner>
      <Container bgColor={bgColor}>
        <Content>
          <Logo src={LogoPNG} />
          <MiniLogo src={MiniLogoPNG} />
          <Right>
            {!isTablet && <Menu />}
            {isTablet && (
              <Popover
                trigger="click"
                content={<Menu />}
                placement="bottomLeft"
              >
                <MdMenu size={28} color="#fff" />
              </Popover>
            )}
          </Right>
        </Content>
      </Container>
    </Wrap>
  );
};

Header.defaultProps = {};

export default Header;
