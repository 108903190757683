import React from 'react';

import { Rombo, Section } from '~/components';

import { Container, Content, Testimony, Text } from './styles';

function Testimonials() {
  return (
    <Container id="testimonials">
      <Section title="O QUE DIZEM NOSSOS CLIENTES">
        <Content>
          <Testimony>
            <Rombo>
              Isaac Santos
              {'\n'}
              Investidor
            </Rombo>
            <Text>
              “Há quase 10 anos venho investindo em imóveis. Já comprei alguns
              imóveis em leilão e tive resultados surpreendentes. O interessante
              em comprar em leilão com a Malsa é que ela faz toda a analise de
              risco do negócio e isso me transmite bastante segurança e ótimos
              retornos financeiros.”
            </Text>
          </Testimony>

          <Testimony>
            <Rombo>
              H. T. Anjos.
              {'\n'}
              Comprador
            </Rombo>
            <Text>
              "Excelente negociação, tudo que conversamos foi atendido dentro do
              prazo. Recomendo, excelente equipe!"
            </Text>
          </Testimony>

          <Testimony>
            <Rombo>
              Maria Brito
              {'\n'}
              Investidora
            </Rombo>
            <Text>
              "Fui muito bem atendida, todas as dúvidas esclarecidas e se
              mantiveram a disposição para qualquer esclarecimento. A venda foi
              rápida e eficiente, trabalho documental organizado. Espero que
              façamos novos negócios. O benefício foi muito bom, só contribuí
              com confiança e determinação. Obrigado."
            </Text>
          </Testimony>
        </Content>
      </Section>
    </Container>
  );
}

export default Testimonials;
