import styled, { css } from 'styled-components';

export const Wrap = styled.div`
  position: relative;
`;

export const ChildrenContainer = styled.div``;

export const Container = styled.div`
  background: rgb(251, 251, 251);
  border-radius: 5px;
  box-shadow: 0 14px 30px rgba(103, 132, 187, 0.25),
    0 4px 4px rgba(103, 132, 187, 0.15);
  position: absolute;
  transition: opacity 0.2s ease;
  top: ${props => `calc(100% + ${props.trigger === 'click' ? '8px' : '0px'})`};
  z-index: 999;
  right: 0px;
  width: 200px;

  &:before {
    content: '';
    position: absolute;
    top: -7px;
    right: 4px;
    width: 0px;
    height: 0px;
    z-index: 2;
    border-style: solid;
    border-width: 0px 8px 8px;
    border-color: transparent transparent rgb(251, 251, 251);
    color: rgb(64, 78, 92);

    ${props =>
      props.placement === 'center' &&
      css`
        right: ${props.arrowRight}px;
      `}
  }
`;
