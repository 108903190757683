import styled from 'styled-components';

export const Container = styled.div``;

export const Wave = styled.div`
  position: relative;
  height: 70px;
  width: 100%;
  background: #f67600;

  &:before {
    content: '';
    display: block;
    position: absolute;
    border-radius: 100% 50%;
    width: 340px;
    height: 80px;
    background-color: white;
    right: -5px;
    top: 40px;
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    border-radius: 100% 50%;
    width: 300px;
    height: 20px;
    background-color: #f67600;
    left: 0;
    top: 27px;
  }
`;
