import styled from 'styled-components';

import SectionAboutImage from '~/assets/img/section-about.jpg';

export const Container = styled.div`
  background-image: url(${SectionAboutImage});
  height: 60vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-repeat-x: no-repeat;
  background-repeat-y: no-repeat;
  background-position: center center;
  background-position-x: center;
  background-position-y: center;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Shadow = styled.div`
  background: rgba(0, 17, 64, 0.6);
  height: 60vh;
  width: 100%;
  position: absolute;
  border: 0px;
  border-top: 8px;
  border-bottom: 8px;
  border-style: solid;
  border-color: #f37220;
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  color: #fff;
  width: 100%;
  max-width: 1380px;
  z-index: 10;
  padding: 100px 30px;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px;
  }

  @media (max-height: 720px) and (max-width: 768px) {
    padding: 50px 30px;
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h2`
  text-align: center;
  color: #fff;
  z-index: 999;
  @media (max-height: 720px) and (max-width: 768px) {
    font-size: 16px;
    margin-bottom: 0px;
  }
`;

export const List = styled.div`
  display: block;
  list-style-type: disc;
  margin-top: 60px;
  font-weight: 600;

  li {
    margin: 16px 0px;

    @media (max-height: 720px) and (max-width: 768px) {
      margin: 8px 0px;
      font-size: 14px;
    }

    @media (max-height: 720px) and (max-width: 425px) {
      font-size: 12px;
    }
  }

  @media (max-width: 768px) {
    margin-top: 20px;
    font-size: 12px;
    text-align: center;
  }
`;

export const SubTitle = styled.h4`
  text-align: left;
  margin-top: 10px;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const Divider = styled.div`
  align-self: center;
  background-color: #fff;
  width: 2px;
  height: 100%;
  margin-top: 100px;
  margin-right: 30px;
  margin-left: 30px;

  /* @media (max-width: 1024px) {
    max-width: 400px;
  } */

  @media (max-width: 768px) {
    width: 100%;
    height: 2px;
    border: 1px dashed #f37220;
    margin: 10px 0px;
  }

  @media (max-height: 720px) and (max-width: 768px) {
    margin: 4px 0px;
  }
`;
