import React from 'react';
import { Helmet } from 'react-helmet';

import { Header, Footer } from '~/components';

import {
  History,
  About,
  Characteristics,
  Contact,
  Testimonials,
} from './Sections';
import { Container } from './styles';

function index() {
  return (
    <Container>
      <Helmet>
        <title>Malsa</title>
      </Helmet>

      <Header />

      <History />
      <Characteristics />
      <Testimonials />
      <About />
      <Contact />
      <Footer />
    </Container>
  );
}

export default index;
