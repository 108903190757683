import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 0px 30px;
  justify-content: center;
  margin-bottom: 40px;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  margin: 0 auto;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

export const Left = styled.div`
  font-family: Roboto;
  margin-right: 60px;

  p {
    margin: 20px 0px;
  }

  @media (max-width: 768px) {
    margin-right: 0px;
  }
`;

export const Right = styled.div`
  font-family: Roboto;

  p {
    margin: 20px 0px;
  }

  &.d-block {
    display: block;
  }

  @media (max-width: 768px) {
    margin-right: 0px;
    display: none;
  }
`;

export const ShowMoreButton = styled.div`
  display: none;
  font-weight: 900;
  background-color: #f37220;
  padding: 8px;
  color: #fff;
  border-radius: 4px;
  @media (max-width: 768px) {
    display: block;
  }
`;
