import React from 'react';
import { hot } from 'react-hot-loader/root';
import { Router } from 'react-router-dom';

import history from '~/services/history';

import Routes from './routes';
import GlobaStyles from './styles/global';

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <Router history={history}>
      <Routes />
      <GlobaStyles />
      <ToastContainer hideProgressBar />
    </Router>
  );
}

export default hot(App);
