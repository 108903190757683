import React from 'react';

import { Section } from '~/components';

import { Container, Content, Left } from './styles';

function History() {
  return (
    <Container id="history">
      <Section title="NOSSA HISTÓRIA">
        <Content>
          <Left>
            <p>
              Durante a crise financeira que o Brasil passou em 2017, tivemos a
              visão de transformar o que era feito como hobby em um negócio.
              Desde então, nos especializamos, pesquisamos, contratamos
              consultorias, fizemos parcerias e desenvolvemos sistemas, tudo
              para aperfeiçoar o negócio e atingir a maior rentabilidade
              possível.
              <p>
                Como a pesquisa e a tecnologia são diferenciais em qualquer
                organização e faz com que o negócio seja feito com mais
                eficiência, eficácia, excelência e resultado, transformamos a
                pesquisa e a tecnologia em nossos pilares para que sejamos
                diferentes e melhores.
              </p>
              <p>
                Com isso, fundamos a Malsa em 2019, uma empresa especializada em
                leilões de imóveis e que conta com pessoas com mais de 10 anos
                de experiência e com foco em resultados, alcançando ganhos com
                alta rentabilidade, muito superior a média, e numa área de baixo
                risco.
              </p>
              Invista em imóveis e enriqueça.
            </p>
          </Left>
        </Content>
      </Section>
    </Container>
  );
}

export default History;
