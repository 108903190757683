import React from 'react';
import { FaFacebook, FaInstagram } from 'react-icons/fa';

import LogoFooter from '~/assets/img/logo-footer.png';

import {
  Container,
  Content,
  Logo,
  Sections,
  Section,
  SectionTitle,
  SectionText,
  SocialSection,
} from './styles';

function Footer() {
  return (
    <Container>
      <Content>
        <Logo src={LogoFooter} />

        <Sections>
          <Section style={{ marginBottom: 15 }}>
            <SectionTitle>Endereço</SectionTitle>
            <SectionText>Simões Filho-BA, Brasil.</SectionText>
          </Section>
          <Section>
            <SectionTitle>Contato</SectionTitle>
            <SectionText style={{ fontFamily: 'Roboto' }}>
              +55 71 99127-7777
            </SectionText>
          </Section>
        </Sections>

        <SocialSection>
          <FaFacebook size={24} color="#fff" />
          <FaInstagram size={24} color="#fff" />
        </SocialSection>
      </Content>
    </Container>
  );
}

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
