import styled from 'styled-components';

import BannerImage from '~/assets/img/banner.jpg';

export const Banner = styled.div`
  background-image: url(${BannerImage});
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-repeat-x: no-repeat;
  background-repeat-y: no-repeat;
  background-position: center center;
  background-position-x: center;
  background-position-y: center;
  margin-bottom: 40px;
`;

export const Shadow = styled.div`
  background: rgba(0, 17, 64, 0.6);
  height: 100%;
  width: 100%;
`;

export const Wrap = styled.div`
  .text-container {
    align-items: center;
    height: 100%;
    position: static;
  }

  .text-content {
    display: block;
  }
`;

export const MiniLogoText = styled.img`
  height: 45px;
  margin-left: 3px;
  margin-right: 5px;

  @media (max-width: 768px) {
    height: 40px;
  }
`;

export const AndText = styled.p`
  font-size: 40px;
  display: inline;

  @media (max-width: 768px) {
    font-size: 30px;
  }
`;

export const Container = styled.div`
  position: fixed;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  width: 100%;
  height: 72px;
  padding: 0px 30px;
  top: 0px;
  z-index: 9998;
  transition: all 0.5s ease-in-out 0s;
  opacity: 1;
  transform: translateY(0px);
  visibility: visible;
  background-color: ${props => props.bgColor};
  padding: ${props => (props.bgColor === 'transparent' ? '55px' : '40px')};

  @media (max-width: 768px) {
    padding: 0px;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1440px;
  display: flex;
  -webkit-box-pack: justify;
  align-items: center;
  color: rgb(255, 255, 255);

  @media (min-width: 1440px) {
  }

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

export const Logo = styled.img`
  margin-right: 40px;

  @media (max-width: 768px) {
    display: none;
  }

  @media (max-width: 1024px) {
    margin-right: 15px;
  }
`;

export const MainTextContainer = styled.div`
  align-self: center;
  display: flex;
  color: #fff;
`;

export const Text = styled.p`
  font-size: 40px;

  @media (max-width: 768px) {
    font-size: 30px;
  }
`;

export const TextProperty = styled.p`
  font-size: 55px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 45px;
  }
`;

export const OpportunityButton = styled.div`
  font-size: 20px;
  font-weight: bold;
  padding: 12px;
  border-radius: 16px;
  width: 250px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 50px;
  background-color: #f37220;
  cursor: pointer;

  &:hover {
    background-color: #001140;
  }

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

export const MiniLogo = styled.img`
  display: none;
  height: 40px;

  @media (max-width: 768px) {
    display: block;
  }
`;

export const Right = styled.div`
  display: flex;
  margin-left: 5px;

  @media (max-width: 1440px) {
    margin-left: 15px;
  }

  @media (max-width: 768px) {
    margin-left: auto;
  }
`;

export const Links = styled.div`
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Link = styled.a`
  color: #fff;
  font-size: 20px;
  letter-spacing: 5px;
  text-transform: uppercase;
  padding: 0px 16px;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: #f37835;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    letter-spacing: 3px;
    color: #000;
    padding: 20px 16px;
    font-weight: 500;
    text-align: center;
    margin: 15px 0px;
  }

  @media (max-width: 1024px) {
    font-size: 16px;
    padding: 0px 10px;
  }
`;
