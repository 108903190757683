import React from 'react';

import PropTypes from 'prop-types';

import { Container, Content, Text } from './styles';

function Rombo({ children, bgColor }) {
  return (
    <Container>
      <Content bgColor={bgColor}>
        <Text bgColor={bgColor}>{children}</Text>
      </Content>
    </Container>
  );
}

Rombo.propTypes = {
  bgColor: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

Rombo.defaultProps = {
  bgColor: '#001140',
};

export default Rombo;
